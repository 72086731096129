import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withModifiers as _withModifiers, isRef as _isRef, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-465872fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "big" }
const _hoisted_2 = ["onTouchstart"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "line" }

import { NumberKeyboard,Field } from 'vant';
import { ref, watch } from 'vue-demi';

export default {
  setup(__props) {

let show = ref(true)
let showLine = ref(true)
const inputRef = ref(null)
const inputClick = (e)=>{
  show.value = true;
  showLine.value = true
}
let numList = ref([])
// 获取输入的数字
const getNumber = (val)=>{
  if(val){
    const numContent = {id:numList.value.length,isShowLine:false,val}
    numList.value.push(numContent)
  }
}
// 删除数字
const deleteNumber = ()=>{
  numList.value.pop()
}
// 点击数字定位光标
const numClick = (id)=>{
  showLine.value = false
  numList.value.forEach(item => {
    item.isShowLine = false
    if(item.id == id){
      item.isShowLine = true
    }
  });
  console.log(numList.value);
}
// let currentLength;
// document.addEventListener("click", function (e) {
//   if(inputRef.value&&inputRef.value.contains(e.target)){
    
//   }
// })

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onTouchstart: _withModifiers(inputClick, ["stop"]),
      class: "content_box"
    }, [
      _createElementVNode("div", {
        class: "content",
        ref_key: "inputRef",
        ref: inputRef
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(numList), (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "num",
            onClick: $event => (numClick(item.id))
          }, [
            _createTextVNode(_toDisplayString(item.val) + " ", 1),
            _withDirectives(_createElementVNode("div", _hoisted_4, null, 512), [
              [_vShow, item.isShowLine]
            ])
          ], 8, _hoisted_3))
        }), 256))
      ], 512)
    ], 40, _hoisted_2),
    _createVNode(_unref(NumberKeyboard), {
      show: _unref(show),
      maxlength: "6",
      onInput: getNumber,
      onDelete: deleteNumber,
      onBlur: _cache[0] || (_cache[0] = $event => {_isRef(show) ? show.value = false : show = false;_isRef(showLine) ? showLine.value = false : showLine = false})
    }, null, 8, ["show"])
  ]))
}
}

}